import React from 'react';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import Layout from '@components/layout';
import { CustomSearchBox } from '@components/search/customSearchBox';
import { SearchHits } from '@components/search/searchHits';
import { searchClient } from '@lib/algolia';

// const IndexResults = connectStateResults(({ searchState, searchResults, children }) =>
//     searchState.query && searchResults && searchResults.nbHits !== 0 ? children : <></>
// );

const Search = () => {
  return (
    <Layout reverseHeader={true}>
      <div className='py-36 md:py-48 px-gutter min-h-screen bg-green-tint'>
        <InstantSearch searchClient={searchClient} indexName='globalSearch'>
          <Configure hitsPerPage={600} />
          <CustomSearchBox placeholder='Search' />
          {/* <IndexResults> */}
          <SearchHits />
          {/* </IndexResults> */}
        </InstantSearch>
      </div>
    </Layout>
  );
};

export default Search;
